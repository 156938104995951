<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card">
          <div class="row">
            <div class="col-lg-12">
              <div class="dash_card_body pb-0">
                <div class="header_section">
                  <h4 class="menu_header">Customers</h4>                  
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group has-search">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="customerSearch" @Keyup="searchCustomers()" class="form-control"
                          placeholder="Search by name,email,phone">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mr-2" @click="searchCustomers()">
                          <span v-if="searchingType == 'search'"><span v-html="searchspinner"></span></span> Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>     
              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Email</th>
                        <th scope="col">Orders</th>
                        <th scope="col">Order Amount</th>
                        <th scope="col">Latest Order </th>
          
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="customer in customers" :key="customer.id">
                        <td>
                          <router-link :to="{ name: 'customer_info', params: { id:customer.id }}">
                            <span class="title_text">{{customer.name}}</span>
                          </router-link>
                        </td>
                        <td>
                          {{customer.phone ? customer.phone : 'N/A'}}
                        </td>
                        <td>
                          {{customer.email ? customer.email :'N/A'}}
                        </td>
                        <td>
                          {{ customer.order.total_order }}
                        </td>
                        <td>
                          {{ formatPrice(customer.order.total_amount) }}
                        </td>
                        <td>
                          <span v-if="customer.latest_order.length !=0">
                            {{formatDate(customer.latest_order.created_at,1)}}
                          </span>
                        </td>
                      </tr>
                        
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="6">
                          <div id="pagination" >
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                  <option :value="100">100</option>
                                </select>
                              </li>
    
                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page"
                              @pagechanged="onPageChange" />
    
                          </div>
    
                        </td>
                      </tr>
                    </tfoot>
                  </table>
    
                </div>
                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>
    
              </div>
            </div>
    
          </div>
                 
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
    import moment from 'moment';
    import ReusableDataMixins from '@/mixins/ReusableDataMixins';
    import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
    import Pagination from '@/pages/pagination/pagination.vue';
  //   import Multiselect from '@vueform/multiselect';
    export default {
        components: {
            Pagination,
          //   Multiselect
        },
        data() {
            return {
                start: '',
                end: '',
                customers: [],
                loading: false,
                page: 1,
                totalPages: 0,
                totalRecords: 0,
                status:'',
                recordsPerPage: 20,
                debounce: null,
                customerSearch: '',
                
            }
        },
        beforeMount: async function () {
          this.getCustomers();
        },
        computed: {
            minDate() {
                return this.start;
            }
        },
        methods: {
            customFormatter(date) {
                return moment(date).format('YYYY-MM-DD');
            },
            searchCustomers() {
              clearTimeout(this.debounce);
              this.debounce = setTimeout(() => {
                  this.getCustomers();
              }, 1000)
          },
            async getCustomers(){
              try{ 
                  this.loading = true;
                  let url = `${this.dashBaseUrl}/all-customers/${this.merchantID}`;
                  url = new URL(url);
                  url.searchParams.set('page', this.page);
                  url.searchParams.set('size', this.recordsPerPage);
                  if(this.customerSearch !=''){
                      url.searchParams.set('search', this.customerSearch)
                  }
                  if(this.status !=''){
                    url.searchParams.set('status', this.status)
                  }
                  const response = await this.axios.get(url,this.apiRequestConfig());
                  if(response.status == 200){
                      this.loading = false;
                      this.customers = response.data.data.data;
                      this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                      this.totalRecords = response.data.data.total;
                  }
  
              }catch(error){
                  this.loading = false;
                  if (error.response.status === 401) {
                          this.$router.push({
                              name: 'login'
                          });
                  }
              }
            },
           
            onPageChange(page) {
                this.page = page
                this.getCustomers();
            },
            onChangeRecordsPerPage() {
                this.getCustomers();
                // ds
            }
        },
        mixins: [ReusableFunctionMixins, ReusableDataMixins]
    }
    </script>
    